import * as api from "./rest_client.js";
import * as t from "./extractions.types.generated";

export interface IExtractions {

    /** Retrieve audit-log for specific entity */
    getAuditLogForEntity(req: t.IGetAuditLogForEntityReq): Promise<t.IGetAuditLogForEntityRes>;


    /** List all jobs */
    listJobs(req: t.IListJobsReq): Promise<t.IListJobsRes>;


    /** Get job details */
    getJobDetails(req: t.IGetJobDetailsReq): Promise<t.IGetJobDetailsRes>;


    /** Export template */
    exportTemplate(req: t.IExportTemplateReq): Promise<t.IExportTemplateRes>;


    /** Get template */
    getTemplate(req: t.IGetTemplateReq): Promise<t.IGetTemplateRes>;


    /** Update existing template */
    updateTemplate(req: t.IUpdateTemplateReq): Promise<t.IUpdateTemplateRes>;


    /** Delete existing template */
    deleteTemplate(req: t.IDeleteTemplateReq): Promise<t.IDeleteTemplateRes>;


    /** List all templates */
    listTemplates(req: t.IListTemplatesReq): Promise<t.IListTemplatesRes>;


    /** Create new template */
    createTemplate(req: t.ICreateTemplateReq): Promise<t.ICreateTemplateRes>;


    /** Import a template */
    importTemplate(req: t.IImportTemplateReq): Promise<t.IImportTemplateRes>;


    /** Evaluate template on given data */
    evaluateTemplate(req: t.IEvaluateTemplateReq): Promise<t.IEvaluateTemplateRes>;


    /** Get evaluation results for a template */
    getEvaluationResults(req: t.IGetEvaluationResultsReq): Promise<t.IGetEvaluationResultsRes>;


    /** Get examples for a template */
    getTemplateExamples(req: t.IGetTemplateExamplesReq): Promise<t.IGetTemplateExamplesRes>;


    /** Create new example for a template */
    createNewExample(req: t.ICreateNewExampleReq): Promise<t.ICreateNewExampleRes>;


    /** Create new example for a template from an item */
    createExampleFromItem(req: t.ICreateExampleFromItemReq): Promise<t.ICreateExampleFromItemRes>;


    /** Update example for a template */
    updateExample(req: t.IUpdateExampleReq): Promise<t.IUpdateExampleRes>;


    /** Delete example for a template */
    deleteExample(req: t.IDeleteExampleReq): Promise<t.IDeleteExampleRes>;


    /** List all templates for download */
    listTemplatesForDownload(req: t.IListTemplatesForDownloadReq): Promise<t.IListTemplatesForDownloadRes>;


    /** Create new endpoint */
    createEndpoint(req: t.ICreateEndpointReq): Promise<t.ICreateEndpointRes>;


    /** Update existing endpoint */
    updateEndpoint(req: t.IUpdateEndpointReq): Promise<t.IUpdateEndpointRes>;


    /** Delete existing endpoint */
    deleteEndpoint(req: t.IDeleteEndpointReq): Promise<t.IDeleteEndpointRes>;


    /** List all endpoints */
    listEndpoints(req: t.IListEndpointsReq): Promise<t.IListEndpointsRes>;


    /** Get single endpoint API key */
    getEndpointAPIKey(req: t.IGetEndpointAPIKeyReq): Promise<t.IGetEndpointAPIKeyRes>;


    /** Create new endpoint API key */
    createEndpointAPIKey(req: t.ICreateEndpointAPIKeyReq): Promise<t.ICreateEndpointAPIKeyRes>;


    /** Delete endpoint API key */
    deleteEndpointAPIKey(req: t.IDeleteEndpointAPIKeyReq): Promise<t.IDeleteEndpointAPIKeyRes>;


    /** Get endpoint */
    getEndpoint(req: t.IGetEndpointReq): Promise<t.IGetEndpointRes>;


    /** Update IP whitelist for an endpoint */
    updateEndpointIPWhitelist(req: t.IUpdateEndpointIPWhitelistReq): Promise<t.IUpdateEndpointIPWhitelistRes>;


    /** Get all user templates */
    getUserTemplates(req: t.IGetUserTemplatesReq): Promise<t.IGetUserTemplatesRes>;


    /** Get user template */
    getUserTemplate(req: t.IGetUserTemplateReq): Promise<t.IGetUserTemplateRes>;


    /** Log template definition download */
    logTemplateDefDownload(req: t.ILogTemplateDefDownloadReq): Promise<t.ILogTemplateDefDownloadRes>;


    /** Resend webhook data for given job */
    resendWebhookData(req: t.IResendWebhookDataReq): Promise<t.IResendWebhookDataRes>;


    /** Get list of open extract confirmations */
    getOpenExtractConfirmations(req: t.IGetOpenExtractConfirmationsReq): Promise<t.IGetOpenExtractConfirmationsRes>;


    /** Update the scrape of an extract confirmation */
    updateExtractConfirmationScrape(req: t.IUpdateExtractConfirmationScrapeReq): Promise<t.IUpdateExtractConfirmationScrapeRes>;


    /** Update the status of an item of an extract confirmation */
    updateExtractConfirmationItemStatus(req: t.IUpdateExtractConfirmationItemStatusReq): Promise<t.IUpdateExtractConfirmationItemStatusRes>;


    /** Executes the code that transforms val_raw to val for all records for all scrapes of item */
    executeItemPostprocessing(req: t.IExecuteItemPostprocessingReq): Promise<t.IExecuteItemPostprocessingRes>;


    /** Set active version of a lookup table */
    setLookupTableActiveVersion(req: t.ISetLookupTableActiveVersionReq): Promise<t.ISetLookupTableActiveVersionRes>;


    /** List all rows of a lookup table */
    listLookupTableRows(req: t.IListLookupTableRowsReq): Promise<t.IListLookupTableRowsRes>;


    /** Add a new row to a lookup table */
    addLookupTableRow(req: t.IAddLookupTableRowReq): Promise<t.IAddLookupTableRowRes>;


    /** Update a row of a lookup table */
    updateLookupTableRow(req: t.IUpdateLookupTableRowReq): Promise<t.IUpdateLookupTableRowRes>;


    /** Delete a row of a lookup table */
    deleteLookupTableRow(req: t.IDeleteLookupTableRowReq): Promise<t.IDeleteLookupTableRowRes>;


    /** Create a new endpoint to lookup table mapping */
    createEndpointToLookupTableMapping(req: t.ICreateEndpointToLookupTableMappingReq): Promise<t.ICreateEndpointToLookupTableMappingRes>;


    /** Update an existing endpoint to lookup table mapping */
    updateEndpointToLookupTableMapping(req: t.IUpdateEndpointToLookupTableMappingReq): Promise<t.IUpdateEndpointToLookupTableMappingRes>;


    /** Delete an existing endpoint to lookup table mapping */
    deleteEndpointToLookupTableMapping(req: t.IDeleteEndpointToLookupTableMappingReq): Promise<t.IDeleteEndpointToLookupTableMappingRes>;


    /** List all endpoint to lookup table mappings */
    listEndpointToLookupTableMappings(req: t.IListEndpointToLookupTableMappingsReq): Promise<t.IListEndpointToLookupTableMappingsRes>;


    /** Get an endpoint to lookup table mapping by id */
    getEndpointToLookupTableMapping(req: t.IGetEndpointToLookupTableMappingReq): Promise<t.IGetEndpointToLookupTableMappingRes>;


    /** Get subscription details */
    getSubscriptionDetails(req: t.IGetSubscriptionDetailsReq): Promise<t.IGetSubscriptionDetailsRes>;


    /** Update existing subscription */
    updateSubscription(req: t.IUpdateSubscriptionReq): Promise<t.IUpdateSubscriptionRes>;


    /** Delete/cancel existing subscription */
    cancelSubscription(req: t.ICancelSubscriptionReq): Promise<t.ICancelSubscriptionRes>;


    /** Get referrals given some filters */
    getReferrals(req: t.IGetReferralsReq): Promise<t.IGetReferralsRes>;


    /** Create a new referral */
    createNewReferral(req: t.ICreateNewReferralReq): Promise<t.ICreateNewReferralRes>;


    /** Get subscriptions given some filters */
    getSubscriptions(req: t.IGetSubscriptionsReq): Promise<t.IGetSubscriptionsRes>;


    /** Create a new manual subscription */
    createNewManualSubscription(req: t.ICreateNewManualSubscriptionReq): Promise<t.ICreateNewManualSubscriptionRes>;


    /** Get organizations given some filters */
    getOrganizations(req: t.IGetOrganizationsReq): Promise<t.IGetOrganizationsRes>;


    /** Send an email digest */
    sendEmailDigest(req: t.ISendEmailDigestReq): Promise<t.ISendEmailDigestRes>;


    /** Retrieve audit log for given context */
    getContextAuditLog(req: t.IGetContextAuditLogReq): Promise<t.IGetContextAuditLogRes>;


    /** Get list of all models */
    getModels(req: t.IGetModelsReq): Promise<t.IGetModelsRes>;


    /** Update model overrides for an organization */
    updateModelOverrides(req: t.IUpdateModelOverridesReq): Promise<t.IUpdateModelOverridesRes>;


    /** Get handlebars result */
    getHandlebarsResult(req: t.IGetHandlebarsResultReq): Promise<t.IGetHandlebarsResultRes>;


    /** Get handlebars examples for given endpoint */
    getHandlebarExamples(req: t.IGetHandlebarExamplesReq): Promise<t.IGetHandlebarExamplesRes>;


    /** Validate JS code */
    validateJsCode(req: t.IValidateJsCodeReq): Promise<t.IValidateJsCodeRes>;

}


function enc(s: any): string { return encodeURIComponent("" + s); }

export class Extractions implements IExtractions {

    client: api.IApiClient;

    constructor(client: api.IApiClient) { this.client = client; }

    /** Retrieve audit-log for specific entity */
    async getAuditLogForEntity(req: t.IGetAuditLogForEntityReq): Promise<t.IGetAuditLogForEntityRes> {
        const reqx: any = req;
        const url = `/api/v1.0/audit-log/${enc(req.entity_type)}/${enc(req.entity_uuid)}`;
        delete reqx.entity_type;
        delete reqx.entity_uuid;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** List all jobs */
    async listJobs(req: t.IListJobsReq): Promise<t.IListJobsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/jobs/list`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Get job details */
    async getJobDetails(req: t.IGetJobDetailsReq): Promise<t.IGetJobDetailsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/jobs/get/${enc(req.job_uuid)}`;
        delete reqx.job_uuid;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Export template */
    async exportTemplate(req: t.IExportTemplateReq): Promise<t.IExportTemplateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/template/export/${enc(req.template_uuid)}`;
        delete reqx.template_uuid;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get template */
    async getTemplate(req: t.IGetTemplateReq): Promise<t.IGetTemplateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/template/get/${enc(req.template_uuid)}`;
        delete reqx.template_uuid;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Update existing template */
    async updateTemplate(req: t.IUpdateTemplateReq): Promise<t.IUpdateTemplateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/template/put/${enc(req.template_uuid)}`;
        delete reqx.template_uuid;
        const res = await this.client.put(url, reqx);
        return res.data;
    }


    /** Delete existing template */
    async deleteTemplate(req: t.IDeleteTemplateReq): Promise<t.IDeleteTemplateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/template/delete/${enc(req.template_uuid)}`;
        delete reqx.template_uuid;
        const res = await this.client.delete(url, { params: reqx });
        return res.data;
    }


    /** List all templates */
    async listTemplates(req: t.IListTemplatesReq): Promise<t.IListTemplatesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/templates/list`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Create new template */
    async createTemplate(req: t.ICreateTemplateReq): Promise<t.ICreateTemplateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/templates/create`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Import a template */
    async importTemplate(req: t.IImportTemplateReq): Promise<t.IImportTemplateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/templates/import`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Evaluate template on given data */
    async evaluateTemplate(req: t.IEvaluateTemplateReq): Promise<t.IEvaluateTemplateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/templates/evaluate`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get evaluation results for a template */
    async getEvaluationResults(req: t.IGetEvaluationResultsReq): Promise<t.IGetEvaluationResultsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/templates/get_evaluation_results`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Get examples for a template */
    async getTemplateExamples(req: t.IGetTemplateExamplesReq): Promise<t.IGetTemplateExamplesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/templates/examples`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Create new example for a template */
    async createNewExample(req: t.ICreateNewExampleReq): Promise<t.ICreateNewExampleRes> {
        const reqx: any = req;
        const url = `/api/v1.0/templates/example/create_new`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Create new example for a template from an item */
    async createExampleFromItem(req: t.ICreateExampleFromItemReq): Promise<t.ICreateExampleFromItemRes> {
        const reqx: any = req;
        const url = `/api/v1.0/templates/example/create_from_item`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Update example for a template */
    async updateExample(req: t.IUpdateExampleReq): Promise<t.IUpdateExampleRes> {
        const reqx: any = req;
        const url = `/api/v1.0/templates/example/update`;
        const res = await this.client.put(url, reqx);
        return res.data;
    }


    /** Delete example for a template */
    async deleteExample(req: t.IDeleteExampleReq): Promise<t.IDeleteExampleRes> {
        const reqx: any = req;
        const url = `/api/v1.0/templates/example/delete/${enc(req.item_uuid)}`;
        delete reqx.item_uuid;
        const res = await this.client.delete(url, { params: reqx });
        return res.data;
    }


    /** List all templates for download */
    async listTemplatesForDownload(req: t.IListTemplatesForDownloadReq): Promise<t.IListTemplatesForDownloadRes> {
        const reqx: any = req;
        const url = `/api/v1.0/download/list_templates`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Create new endpoint */
    async createEndpoint(req: t.ICreateEndpointReq): Promise<t.ICreateEndpointRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoints/create`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Update existing endpoint */
    async updateEndpoint(req: t.IUpdateEndpointReq): Promise<t.IUpdateEndpointRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoints/update`;
        const res = await this.client.put(url, reqx);
        return res.data;
    }


    /** Delete existing endpoint */
    async deleteEndpoint(req: t.IDeleteEndpointReq): Promise<t.IDeleteEndpointRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoints/delete/${enc(req.endpoint_uuid)}`;
        delete reqx.endpoint_uuid;
        const res = await this.client.delete(url, { params: reqx });
        return res.data;
    }


    /** List all endpoints */
    async listEndpoints(req: t.IListEndpointsReq): Promise<t.IListEndpointsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoints/list`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Get single endpoint API key */
    async getEndpointAPIKey(req: t.IGetEndpointAPIKeyReq): Promise<t.IGetEndpointAPIKeyRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint/${enc(req.endpoint_uuid)}/keys`;
        delete reqx.endpoint_uuid;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Create new endpoint API key */
    async createEndpointAPIKey(req: t.ICreateEndpointAPIKeyReq): Promise<t.ICreateEndpointAPIKeyRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint/${enc(req.endpoint_uuid)}/keys`;
        delete reqx.endpoint_uuid;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Delete endpoint API key */
    async deleteEndpointAPIKey(req: t.IDeleteEndpointAPIKeyReq): Promise<t.IDeleteEndpointAPIKeyRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint/${enc(req.endpoint_uuid)}/keys`;
        delete reqx.endpoint_uuid;
        const res = await this.client.delete(url, { params: reqx });
        return res.data;
    }


    /** Get endpoint */
    async getEndpoint(req: t.IGetEndpointReq): Promise<t.IGetEndpointRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint/${enc(req.endpoint_uuid)}`;
        delete reqx.endpoint_uuid;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Update IP whitelist for an endpoint */
    async updateEndpointIPWhitelist(req: t.IUpdateEndpointIPWhitelistReq): Promise<t.IUpdateEndpointIPWhitelistRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoints/update_ip_whitelist`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get all user templates */
    async getUserTemplates(req: t.IGetUserTemplatesReq): Promise<t.IGetUserTemplatesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/admin/user_templates`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Get user template */
    async getUserTemplate(req: t.IGetUserTemplateReq): Promise<t.IGetUserTemplateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/admin/user_template`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Log template definition download */
    async logTemplateDefDownload(req: t.ILogTemplateDefDownloadReq): Promise<t.ILogTemplateDefDownloadRes> {
        const reqx: any = req;
        const url = `/api/v1.0/admin/log_template_def_download`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Resend webhook data for given job */
    async resendWebhookData(req: t.IResendWebhookDataReq): Promise<t.IResendWebhookDataRes> {
        const reqx: any = req;
        const url = `/api/v1.0/job/${enc(req.id)}/resend-webhook`;
        delete reqx.id;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get list of open extract confirmations */
    async getOpenExtractConfirmations(req: t.IGetOpenExtractConfirmationsReq): Promise<t.IGetOpenExtractConfirmationsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/extract_confirmation/open`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Update the scrape of an extract confirmation */
    async updateExtractConfirmationScrape(req: t.IUpdateExtractConfirmationScrapeReq): Promise<t.IUpdateExtractConfirmationScrapeRes> {
        const reqx: any = req;
        const url = `/api/v1.0/extract_confirmation/update_scrape`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Update the status of an item of an extract confirmation */
    async updateExtractConfirmationItemStatus(req: t.IUpdateExtractConfirmationItemStatusReq): Promise<t.IUpdateExtractConfirmationItemStatusRes> {
        const reqx: any = req;
        const url = `/api/v1.0/extract_confirmation/update_item_status`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Executes the code that transforms val_raw to val for all records for all scrapes of item */
    async executeItemPostprocessing(req: t.IExecuteItemPostprocessingReq): Promise<t.IExecuteItemPostprocessingRes> {
        const reqx: any = req;
        const url = `/api/v1.0/scrape/execute_item_postprocessing`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Set active version of a lookup table */
    async setLookupTableActiveVersion(req: t.ISetLookupTableActiveVersionReq): Promise<t.ISetLookupTableActiveVersionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/lookup_table/set_active_version`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** List all rows of a lookup table */
    async listLookupTableRows(req: t.IListLookupTableRowsReq): Promise<t.IListLookupTableRowsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/lookup_table/rows/list`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Add a new row to a lookup table */
    async addLookupTableRow(req: t.IAddLookupTableRowReq): Promise<t.IAddLookupTableRowRes> {
        const reqx: any = req;
        const url = `/api/v1.0/lookup_table/row/add`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Update a row of a lookup table */
    async updateLookupTableRow(req: t.IUpdateLookupTableRowReq): Promise<t.IUpdateLookupTableRowRes> {
        const reqx: any = req;
        const url = `/api/v1.0/lookup_table/row/update`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Delete a row of a lookup table */
    async deleteLookupTableRow(req: t.IDeleteLookupTableRowReq): Promise<t.IDeleteLookupTableRowRes> {
        const reqx: any = req;
        const url = `/api/v1.0/lookup_table/row/delete`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Create a new endpoint to lookup table mapping */
    async createEndpointToLookupTableMapping(req: t.ICreateEndpointToLookupTableMappingReq): Promise<t.ICreateEndpointToLookupTableMappingRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint_to_lookup_table/create`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Update an existing endpoint to lookup table mapping */
    async updateEndpointToLookupTableMapping(req: t.IUpdateEndpointToLookupTableMappingReq): Promise<t.IUpdateEndpointToLookupTableMappingRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint_to_lookup_table/update`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Delete an existing endpoint to lookup table mapping */
    async deleteEndpointToLookupTableMapping(req: t.IDeleteEndpointToLookupTableMappingReq): Promise<t.IDeleteEndpointToLookupTableMappingRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint_to_lookup_table/delete`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** List all endpoint to lookup table mappings */
    async listEndpointToLookupTableMappings(req: t.IListEndpointToLookupTableMappingsReq): Promise<t.IListEndpointToLookupTableMappingsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint_to_lookup_table/list`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Get an endpoint to lookup table mapping by id */
    async getEndpointToLookupTableMapping(req: t.IGetEndpointToLookupTableMappingReq): Promise<t.IGetEndpointToLookupTableMappingRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint_to_lookup_table/get`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Get subscription details */
    async getSubscriptionDetails(req: t.IGetSubscriptionDetailsReq): Promise<t.IGetSubscriptionDetailsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/subscription/${enc(req.id)}`;
        delete reqx.id;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Update existing subscription */
    async updateSubscription(req: t.IUpdateSubscriptionReq): Promise<t.IUpdateSubscriptionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/subscription/${enc(req.id)}`;
        delete reqx.id;
        const res = await this.client.put(url, reqx);
        return res.data;
    }


    /** Delete/cancel existing subscription */
    async cancelSubscription(req: t.ICancelSubscriptionReq): Promise<t.ICancelSubscriptionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/subscription/${enc(req.id)}`;
        delete reqx.id;
        const res = await this.client.delete(url, { params: reqx });
        return res.data;
    }


    /** Get referrals given some filters */
    async getReferrals(req: t.IGetReferralsReq): Promise<t.IGetReferralsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/referral`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Create a new referral */
    async createNewReferral(req: t.ICreateNewReferralReq): Promise<t.ICreateNewReferralRes> {
        const reqx: any = req;
        const url = `/api/v1.0/referral`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get subscriptions given some filters */
    async getSubscriptions(req: t.IGetSubscriptionsReq): Promise<t.IGetSubscriptionsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/subscription`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Create a new manual subscription */
    async createNewManualSubscription(req: t.ICreateNewManualSubscriptionReq): Promise<t.ICreateNewManualSubscriptionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/subscription`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get organizations given some filters */
    async getOrganizations(req: t.IGetOrganizationsReq): Promise<t.IGetOrganizationsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/org`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Send an email digest */
    async sendEmailDigest(req: t.ISendEmailDigestReq): Promise<t.ISendEmailDigestRes> {
        const reqx: any = req;
        const url = `/api/v1.0/email_digest/send`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Retrieve audit log for given context */
    async getContextAuditLog(req: t.IGetContextAuditLogReq): Promise<t.IGetContextAuditLogRes> {
        const reqx: any = req;
        const url = `/api/v1.0/context-audit/${enc(req.uuid)}`;
        delete reqx.uuid;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Get list of all models */
    async getModels(req: t.IGetModelsReq): Promise<t.IGetModelsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/models`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Update model overrides for an organization */
    async updateModelOverrides(req: t.IUpdateModelOverridesReq): Promise<t.IUpdateModelOverridesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/org/models_overrides`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get handlebars result */
    async getHandlebarsResult(req: t.IGetHandlebarsResultReq): Promise<t.IGetHandlebarsResultRes> {
        const reqx: any = req;
        const url = `/api/v1.0/handlebars/render`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get handlebars examples for given endpoint */
    async getHandlebarExamples(req: t.IGetHandlebarExamplesReq): Promise<t.IGetHandlebarExamplesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/handlebars/examples`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Validate JS code */
    async validateJsCode(req: t.IValidateJsCodeReq): Promise<t.IValidateJsCodeRes> {
        const reqx: any = req;
        const url = `/api/v1.0/js_validate`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }

}
